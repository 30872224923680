import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';

interface Props {
  children: ReactNode;
  backgroundColor?: string
  textColor?: string
}

const Section: React.FC<Props> = ({ children, backgroundColor, textColor }) => (
  <Box bgcolor={backgroundColor} color={textColor}>
    {children}
  </Box>
);
export default Section;