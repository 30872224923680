import { Hidden } from '@material-ui/core';
import React from 'react';

const FairtiqLogo: React.FC<{ height?: string }> = ({ height = '25px' }) => {
  return (
    <>
      <Hidden implementation="css" smDown>
        <img src="/fairtiQ.svg" height={'30px'} alt="Fairtiq" />
      </Hidden>
      <Hidden implementation="css" xsDown mdUp>
        <img src="/fairtiQ.svg" height={'23px'} alt="Fairtiq" />
      </Hidden>
      <Hidden implementation="css" smUp>
        <img src="/Q.svg" height={height} alt="Fairtiq" />
      </Hidden>
    </>
  );
};

export default FairtiqLogo;