import { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import FoodItem from '../foodItem/FoodItem';
import fairtiqStyles from '../../utils/fairtiqStyles';
import { Graph } from './graph';


const foodMapping = {
    "cheese": 21.2,
    "chocolate": 18.7,
    "beef": 59.6,
}

export default function Emissions() {

    const classes = fairtiqStyles();
    const [state, setState] = useState({
        equivalentCo2EmittedByCarInYear: 0,
        co2EmittedByPublicTransportInYear: 0,
        "co2Saved": "...",
        "cheeseT": "...",
        "chocolateT": "...",
        "beefT": "..."
    });

    const url = "https://live-api-staging.fairtiq.com/v1/emissions/stats/stream";

    useEffect(() => {
        const evtSource = new EventSource(url);

        evtSource.onmessage = function (event) {
            const data = JSON.parse(event.data);

            if (data !== null) {
                let co2SavedKg = (data["equivalentCo2EmittedByCarInYear"] - data["co2EmittedByPublicTransportInYear"]) / 1000;
                let co2SavedTonne = co2SavedKg / 1000;
                // compute CO2 emissions saved in terms of food production
                let cheese = co2SavedTonne * foodMapping["cheese"];
                let chocolate = co2SavedTonne * foodMapping["chocolate"];
                let beef = co2SavedTonne * foodMapping["beef"];

                setState({
                    "equivalentCo2EmittedByCarInYear": data['equivalentCo2EmittedByCarInYear'],
                    "co2EmittedByPublicTransportInYear": data['co2EmittedByPublicTransportInYear'],
                    "co2Saved": Math.round(co2SavedTonne).toLocaleString('en'),
                    "cheeseT": Math.round(cheese).toLocaleString('en'),
                    "chocolateT": Math.round(chocolate).toLocaleString('en'),
                    "beefT": Math.round(beef).toLocaleString('en'),
                });
            }
        }
    }, [url]);

    return (
        <Box className={classes.mainContainer}>
            <Box className={classes.imgContainer}>
                <img src="/green_fairtiq.png" width="100%" alt="Green Fairtiq" />
            </Box>
            <h1 id="co2SavedCounter" className={classes.mainParagraph}> {state.co2Saved} CO<sub>2</sub>tonnes</h1>
            <p className={classes.subText}>saved by traveling with FAIRTIQ since the beginning of the year.</p>
            <Box paddingBottom={4} paddingTop={4}>
                <Graph equivalentCo2EmittedByCarInYear={state.equivalentCo2EmittedByCarInYear} co2EmittedByPublicTransportInYear={state.co2EmittedByPublicTransportInYear} />
            </Box>
            <p className={classes.subText}>This is equivalent to the emissions of:</p>
            <Box display="flex" width="100%" flexDirection="row" justifyContent="space-around" alignItems="center" marginTop="2%">
                <FoodItem imageSrc="/cheese.png" text={`${state.cheeseT} tonnes of cheese`} />
                <FoodItem imageSrc="/chocolate.png" text={`${state.chocolateT} tonnes of chocolate`} />
                <FoodItem imageSrc="/meat.png" text={`${state.beefT} tonnes of beef`} />
            </Box>
        </Box>
    );
}

