import { CssBaseline, ThemeProvider, Box, makeStyles, Theme, createStyles, Container, styled, Link } from '@material-ui/core';
import fairtiqTheme from './utils/theme';

import Header from './components/header/Header';
import Section from './components/section/Section';
import Emissions from './components/emissions/Emissions';
import Info from './components/info/Info';
import MyClimate from './components/myClimate/MyClimate';
import Blogs from './components/blogs/Blogs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '400px',
      marginBottom: theme.spacing(4),
      flexGrow: 2,
    },
    footer: {
      textAlign: 'right',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      marginTop: 'auto',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      color: theme.palette.grey[500],
    },
  }),
);

const WhiteLink = styled(Link)({
  color: "#575b61",
  fontWeight: 'bold'
});

export default function App() {

  const classes = useStyles();

  return (
    <ThemeProvider theme={fairtiqTheme}>
      <CssBaseline />
      <Box className={classes.root}>
        <Header />
        <Box className={classes.content}>

          <Section backgroundColor={fairtiqTheme.palette.primary.dark} textColor="#fff">
            <Emissions />
          </Section>

          <Section>
            <Info />
          </Section>

          <Section backgroundColor={fairtiqTheme.palette.primary.dark} textColor="#fff">
            <MyClimate />
          </Section>

          <Section>
            <Blogs />
          </Section>
          
          <Box width="100vw" textAlign="center">
            <p>
              <br />
            For computation this saving, we used all data from the FAIRTIQ App in an aggregated and anonymized way. For more information about the emission savings see <WhiteLink target="blank" href="https://support.fairtiq.com/hc/en-us/articles/360021394200-CO2-saved-by-using-FAIRTIQ">this FAQ article</WhiteLink>.
            <p></p>For the calculation of the equivalent on food productions we used <WhiteLink target="blank" href="https://ourworldindata.org/environmental-impacts-of-food#you-want-to-reduce-the-carbon-footprint-of-y%5B%E2%80%A6%5D-what-you-eat-not-whether-your-food-is-local">this data source</WhiteLink>.
            </p>
          </Box>

        </Box>
        <footer className={classes.footer}>
          <Container>© 2021 FAIRTIQ</Container>
        </footer>
      </Box>
    </ThemeProvider>
  );
}
