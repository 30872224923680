import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';

const fairtiqGreenStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainParagraph: {
            fontSize: "3.5em",
            margin: 0,
            textAlign: "center"
        },
        secondaryParagraph: {
            fontSize: "3em",
            margin: 0,
            textAlign: "center"
        },
        subText: {
            fontSize: "1.5em",
            margin: 0,
            textAlign: "center",
            fontWeight: 300,
        },
        graphText: {
            fontSize: 17,
            margin: 0,
            textAlign: "center",
            fontWeight: 300,
        },
        mainContainer: {
            display: "flex",
            minHeight: "100vh",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        },
        imgContainer: {
            width: "40%",
            minWidth: "500px"
        },
        foodIconContainer: {
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "2%",
        },
        textLink: {
            '&:link': {
                color: "white"
            },
            '&:visited': {
                color: "white"
            },
            '&:hover': {
                color: "#e21a34"
            }
        },
        innerContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        titleContainer: {
            textAlign: "center",
            width: "65%"
        },
        itemContainer: {
            display: "flex",
            flexDirection: "row",
            '@media (max-width: 1500px)': {
                flexDirection: 'column'
            },
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "100%"
        },
        button: {
            backgroundColor: theme.palette.primary.dark,
            border: "none",
            color: "white",
            width: "65%",
            '@media (max-width: 1500px)': {
                width: '100%'
            },
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            transitionDuration: "0.3s",
            borderRadius: "12px",
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
        },
        imageContainer: {
            width: "50%",
            '@media (max-width: 1500px)': {
                width: '100%'
            },
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
        },
        textContainer: {
            width: "45%",
            '@media (max-width: 1500px)': {
                width: '100%'
            },
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
        },
        blogItemTitle: {
            //color: "#000",
            fontSize: "1.75rem",
            marginTop: 0,
            '@media (max-width: 1500px)': {
                marginTop: '5%'
            },
            lineHeight: 1,
            textAlign: "center"
        },
        blogInnerContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            paddingRight: "5%",
            paddingLeft: "5%"
        },
        blogMainContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
        }
    }),
);

export default function fairtiqStyles() {
    return fairtiqGreenStyles();
}