import React from 'react';
import Box from '@material-ui/core/Box';

interface Props {
    imageSrc: string
    text: string
}

const Section: React.FC<Props> = ({ imageSrc, text }) => (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <img src={imageSrc} height={'100px'} alt="food" />
        <p>{text}</p>
    </Box>
);
export default Section;