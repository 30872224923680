import { Box, Grid, styled } from "@material-ui/core";
import { FC } from "react";
import fairtiqStyles from '../../utils/fairtiqStyles';

const PTBar = styled(Box)({
  backgroundColor: '#2f8d9c',
  borderRadius: 16,
  border: '2px solid #25717d'
});

const CarBar = styled(Box)({
  backgroundColor: '#9c2c40',
  borderRadius: 16,
  border: '2px solid #6e1f2d'

});

const DiffBar = styled(Box)({
  backgroundColor: '#327841',
  borderRadius: 16,
  border: '2px solid #296335',
  fontWeight:'bold'
});

export const Graph: FC<{
  equivalentCo2EmittedByCarInYear: number;
  co2EmittedByPublicTransportInYear: number;
}> = ({ equivalentCo2EmittedByCarInYear, co2EmittedByPublicTransportInYear }) => {
  const heightGraph = 30;
  const heightPT = (co2EmittedByPublicTransportInYear / equivalentCo2EmittedByCarInYear) * heightGraph;
  const heightCar = heightGraph;
  const heightDiff = heightCar - heightPT;
  const classes = fairtiqStyles();
  return (
    <Box height={`${heightGraph}vh`} width="60vw">
      <Grid container
        spacing={2}
        direction="row"
        justify="center"
        alignItems="flex-end">
        <Grid item xs={4} className={classes.graphText}>
          <PTBar display="flex" alignItems="center" justifyContent="center" height={`${heightPT}vh`}>Public transport emissions</PTBar>
        </Grid>
        <Grid item xs={4} className={classes.graphText}>
          <CarBar display="flex" alignItems="center" justifyContent="center" height={`${heightCar}vh`}>Car equivalent emissions</CarBar>
        </Grid>
        <Grid item xs={4} className={classes.graphText}>
          <Box display="flex" flexDirection="column">
            <DiffBar display="flex" alignItems="center" justifyContent="center" height={`${heightDiff}vh`}>CO2 you contributed saving</DiffBar>
            <Box height={`${heightGraph - heightDiff}vh`}></Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
