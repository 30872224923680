import { Box } from '@material-ui/core';
import fairtiqStyles from '../../utils/fairtiqStyles';

interface Props {
    imageSrc: string
    title: string
    description: string
    link: string
}

export default function BlogItem(props: Props) {
    const classes = fairtiqStyles();

    return (
        <Box className={classes.itemContainer}>
            <Box className={classes.imageContainer}>
                <img src={props.imageSrc} width="100%" alt="food" />
            </Box>

            <Box className={classes.textContainer}>
                <h1 className={classes.blogItemTitle}>{props.title}</h1>
                <p>{props.description}</p>
                <a className={classes.button} href={props.link}>Read on</a>
            </Box>
        </Box>
    );
}