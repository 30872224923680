import { AppBar, Box, styled, Container } from '@material-ui/core';
import FairtiqLogo from './FairtiqLogo';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
}));

export default function Header() {
    return (
        <StyledAppBar position="static">
            <Container>
                <Box display="flex" alignItems="center" flexDirection="row" flexGrow={1}>
                    <Box display="flex" alignItems="center" flexGrow={1} padding={3}>
                        <Box lineHeight="1">
                            <FairtiqLogo />
                        </Box>
                    </Box>
                </Box>
            </Container>
        </StyledAppBar>
    )
}