import { Box } from '@material-ui/core';
import BlogItem from './BlogItem';
import fairtiqStyles from '../../utils/fairtiqStyles';
import Grid from '@material-ui/core/Grid';


export default function Blogs() {
    const classes = fairtiqStyles();

    return (
        <Box className={classes.blogMainContainer}>
            <h1 className={classes.secondaryParagraph} style={{ "marginBottom": "5%" }}>Read more on our blog posts</h1>
            <br />
            <Box className={classes.blogInnerContainer}>
                <Grid container spacing={6}>
                    <Grid item xs={6} >
                        <BlogItem
                            imageSrc="/blog_mobility_transition.png"
                            title="Mobility transition and business travel - a contradiction?"
                            description="Life hacks for sustainable business travel"
                            link="https://fairtiq.com/en-ch/blog/life-hacks-for-sustainable-business-travel"
                        />

                    </Grid>
                    <Grid item xs={6}>

                        <BlogItem
                            imageSrc="/blog_green_living.png"
                            title="Green living by FAIRTIQ employees"
                            description="How do FAIRTIQ employees reduce their carbon footprint in their everyday life?"
                            link="https://fairtiq.com/en-ch/blog/green-living-by-fairtiq-employees"
                        />
                    </Grid>
                    <Grid item xs={6}>

                        <BlogItem
                            imageSrc="/blog_certified_green.png"
                            title="We’re certified climate neutral"
                            description="FAIRTIQ is committed to offsetting its climate impact."
                            link="https://fairtiq.com/en-ch/blog/we-are-certified-climate-neutral"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}