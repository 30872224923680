import {Box} from '@material-ui/core';
import fairtiqStyles from '../../utils/fairtiqStyles';

export default function MyClimate() {
    const classes = fairtiqStyles();

    return (
        <Box className={classes.mainContainer}>
            <h1 className={classes.mainParagraph}>
                We’re certified climate neutral
            </h1>
            <Box className={classes.subText}>
                <p >Sustainability is one of FAIRTIQ’s core values.</p>
                <p>That’s why we compensate for all greenhouse gas emissions resulting from the company’s activities.</p>
                <img src="/myclimate/qr-label-white.png" alt="myclimate logo"/>                
            </Box>
        </Box>
    );
}