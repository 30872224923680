import { createMuiTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const fairtiqTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#48BC97',
      dark: '#1AAC87',
    },
    secondary: {
      main: '#3487ae',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#63A375',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#788FE3',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#D57A66',
      contrastText: '#FFFFFF',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: 15,
    h1: {
      fontWeight: 600,
      color: "#00000"
    },
    h2: {
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: 25,
      marginBottom: 12,
    },
    h3: {
      fontWeight: 700,
      fontSize: 16,
      marginBottom: 5,
    },
    h4: {
      fontSize: 14,
      fontWeight: 700,
      fontStyle: 'italic',
    },
    h5: {
      fontSize: 22,
      fontWeight: 700,
    },
    body2: {
      fontSize: 17,
      fontWeight: 300,
      color: "#575b61"
    },
  },
});

fairtiqTheme.overrides = {
  MuiTableRow: {
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: grey[50],
      },
    },
  },
  MuiTableCell: {
    head: {
      backgroundColor: fairtiqTheme.palette.grey[600],
      color: fairtiqTheme.palette.primary.contrastText,
      fontWeight: 700,
    },
  },
  MuiCard: {
    root: {
      backgroundColor: 'rgb(0, 0, 0, 0)',
      boxShadow: 'none !important',
    },
  },
  MuiCardContent: {
    root: {
      padding: '7px 0px',
      '&:last-child': {
        paddingBottom: '10px',
      },
    },
  },
  MuiCardHeader: {
    root: {
      padding: '10px 0px',
    },
  },
  MuiCardActions: {
    root: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  MuiBreadcrumbs: {
    root: {
      marginTop: '-20px',
      marginBottom: '30px',
    },
  },
};

export default fairtiqTheme;