import { Box } from '@material-ui/core';
import fairtiqStyles from '../../utils/fairtiqStyles';

export default function Info() {

    const classes = fairtiqStyles();

    return (
        <Box className={classes.mainContainer}>
            <Box className={classes.innerContainer}>
                <Box className={classes.titleContainer}>
                    <h1 className={classes.mainParagraph}>FAIRTIQ stands for sustainability</h1>
                    <Box textAlign="justify" className={classes.subText}>
                        <p>
                            We are very proud to be a CO<sub>2</sub>-neutral company and are part of the Urban Sustainability Community,
                        which promotes the sustainable development of mobility in cities.
                        <br />
                        FAIRTIQ makes it easier to leave your car at home and to reduce your carbon footprint. However, It can be difficult to grasp your own contribution towards sustainability.
                        That’s why we created this page to show you the impact we  have as a community when we switch to more sustainable means of transport.
                        <br />
                        But this is not the end of the story, because we also want to show what we ourselves are doing as a company to meet the goal of "Net 0".
                    </p>
                    </Box>
                <p className={classes.secondaryParagraph}>Together we can do it!</p>

            </Box>
            </Box>
        </Box>
    );
}